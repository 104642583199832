<template>
    <v-dialog v-model="showDialog" persistent max-width="800">
        <v-card :disabled="loading" :loading="loading">
            <v-card-title>TSE-Server Abfrage</v-card-title>

            <v-toolbar flat dark>
                <v-toolbar-title>Zugangsdaten des TSE-Servers eingeben</v-toolbar-title>
            </v-toolbar>

            <v-card-text>
                <v-row style="margin-top: 30px;">
                    <v-col cols="12" md="6">
                        <v-text-field dense outlined v-model="username" label="Benutzername"></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-text-field dense outlined type="password" v-model="password" label="Passwort"></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <v-btn style="float: right" :disabled="loading || username.length === 0 || password.length === 0" :loading="loading" @click="authenticate" color="success">Anmelden</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-toolbar flat dark>
                <v-toolbar-title>Angeschlossene Geräte</v-toolbar-title>
            </v-toolbar>

            <v-list>
                <v-list-item @click="selectSlot(module)" v-for="(module, slot) in tse_modules" :key="slot">
                    <v-list-item-content>
                        <v-list-item-title>{{ slot }}</v-list-item-title>
                        <v-list-item-subtitle v-if="module !== 'Empty'">TSE_{{ module.tseInformation.serialNumber }}</v-list-item-subtitle>
                        <v-list-item-subtitle v-else>Keine TSE angeschlossen</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <v-card-actions>
                <v-btn text color="error" @click="showDialog = false">{{$t("generic.lang_cancel")}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "FiscalDevicesCreateTSEServerDialog",

    props: {
        serverIP: String,
        deviceIP: String
    },

    data() {
        return {
            showDialog: false,
            loading: false,

            //
            username: "",
            password: "",
            tse_modules: {}
        }
    },

    methods: {
        checkServer() {
            this.showDialog = true;
        },

        async authenticate() {
            this.loading = true;
            //CREATE NEW AXIOS INSTANCE
            let axios = this.axios.create();

            //REMOVE 3POS HEADERS
            delete axios.defaults.headers.common['3POSAPITOKEN'];
            delete axios.defaults.headers.common['3POSBS'];
            delete axios.defaults.headers.common['3POSBSID'];

            axios.post('http://'+this.serverIP+':8040/tse-server/modules', {
                username: this.username,
                password: this.password,
                deviceIP: this.deviceIP
            }).then((res) => {
                if(res.status === 200) {
                    this.tse_modules = res.data.tse_modules;
                }
            }).catch((e) => {
              console.log(e);
            }).finally(() => {
              this.loading = false;
            })
        },
        selectSlot(module) {
            //CHECK IF SLOT IS CONNECTED
            if(module === "Empty")
                return;

            this.$emit("selectSlot", module);

            this.showDialog = false;
        }
    }
}
</script>