<template>
    <div>
        <page-title :heading="$t('settings.lang_fiscalDevice') " :subheading="$t('settings.lang_fiscalDevice')" :icon=icon></page-title>
        <div class="app-main__inner">
        <FiscalDevice></FiscalDevice>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../../Layout/Components/PageTitle.vue";
    import FiscalDevice from "../../../components/settings/fiscal_de/FiscalDevices.vue";

    export default {
        components: {
            PageTitle,
            FiscalDevice
        },

        data: () => ({
            icon: 'pe-7s-plane icon-gradient bg-tempting-azure',
        })
    }
</script>