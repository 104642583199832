<template>
  <v-dialog persistent width="600" v-model="showDialog">
    <v-card>
      <v-card-title>{{ $t("generic.lang_tseSetup") }}</v-card-title>
      <v-card-text class="text-center" v-if="tseLoading">
        <p>
          {{ $t("generic.lang_tseIsSetUp") }}...
        </p>

        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-card-text>

      <v-card-text v-if="!tseLoading && tseErrorMessage.length > 0" class="text-center">
        <b>{{ $t("generic.lang_error") }}</b>
        <br><span v-html="tseErrorMessage"></span>
      </v-card-text>

      <v-card-actions v-if="!tseLoading && tseErrorMessage.length > 0">
        <v-btn text color="error" @click="cancelSetup" small>{{$t("generic.lang_cancel")}}</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="warning" @click="checkDevice">{{ $t('generic.lang_retry') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {Events} from "@/plugins/events";

export default {
  name: "FiscalDeviceSetup",

  props: {
    fiscalDeviceName: String,
    fiscalDeviceIP: String,
    fiscalDeviceID: String,
    useProxy: Boolean,
    useSSL: Boolean,
    proxyServerIP: String,
    skipCallbackEvents: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      tseLoading: false,
      tseErrorMessage: "",
      tseDevice: null
    }
  },

  computed: {
    showDialog() {
      return this.tseLoading || this.tseErrorMessage.length > 0;
    }
  },

  mounted() {
    Events.$on("eposDevice", this.eposDeviceCallback);
    Events.$on("GFE_OnReceive", this.GFE_OnReceiveCallback);
  },

  beforeDestroy() {
    Events.$off("eposDevice", this.eposDeviceCallback);
    Events.$off("GFE_OnReceive", this.GFE_OnReceiveCallback)
  },

  methods: {
    closeDialog() {
      this.tseLoading = false;
      this.tseErrorMessage = "";

      //DISCONNECT FROM DEVICE
      if (this.tseDevice !== null)
        this.tseDevice.disconnect(true);
    },
    checkDevice() {
      this.tseLoading = true;
      this.tseErrorMessage = "";

      //DISCONNECT FROM DEVICE
      if (this.tseDevice !== null)
        this.tseDevice.disconnect(true);

      this.startSetup();
    },
    startSetup() {
      this.tseLoading = true;
      console.log("start Setup");
      let id = "DEVICE_SETUP_" + this.fiscalDeviceIP + "_" + this.fiscalDeviceID;

      this.$eposClass.addTSEPrinter(new this.$epson.ePOSDevice(), {
        id: id, //ID === NAME
        ip: this.fiscalDeviceIP,
        TSEProxyIPAdress: this.proxyServerIP,
        port: (this.useProxy === true ? 8009 : (this.useSSL === true ? 8043 : 8008)),
        deviceID: this.fiscalDeviceID,
        adminID: 'Administrator',
        clientID: null
      }, false, false, this.useProxy);

      this.tseDevice = this.$eposClass.getTSEPrinter(id);

      console.log(this.tseDevice);
      console.log("finish Setup");

    },

    cancelSetup() {
      this.closeDialog();

      this.$emit("cancelSetup");
    },
    setupFinish(logCertificate, payload) {
      //SAVE DATA
      this.closeDialog();

      this.$emit("setupFinish", {
        serial: payload.tseInformation.serialNumber,
        signatureAlgorithm: payload.tseInformation.signatureAlgorithm,
        publicKey: payload.tseInformation.tsePublicKey,
        certificate: logCertificate
      });
    },

    // --- CALLBACKS ---
    eposDeviceCallback(payload) {
      console.log(payload);
      //CHECK IF WE CAN SKIP EVENTS
      if (this.skipCallbackEvents)
        return;

      if (payload.action === "createDeviceCallback.success") {
        // this.tseDevice.GFE_Setup();
      }

      if (payload.action === "createDeviceCallback.error") {
        if (payload.code === "DEVICE_IN_USE") {
          this.tseLoading = false;
          this.tseErrorMessage = `Einrichtung der TSE konnte nicht gestartet werden, da bereits eine Kasse mit der TSE verbunden ist.`;
        } else {
          this.tseLoading = false;
          this.tseErrorMessage = `Einrichtung der TSE konnte nicht gestartet werden.<br>(${payload.code})`;
        }
      }

      if (payload.action === "connectCallback.error") {
        //CONNECTION ERROR
        this.tseLoading = false;
        this.tseErrorMessage = "Verbindung mit der TSE konnte nicht aufgebaut werden. Bitte überprüfe die IP-Adresse und stelle sicher, dass das Gerät eingeschaltet ist."
      }

      if (payload.action === "disconnect") {
        this.tseDevice = null;
      }
    },
    GFE_OnReceiveCallback(payload) {
      console.log(payload);
      //CHECK IF WE CAN SKIP EVENTS
      if (this.skipCallbackEvents)
        return;

      if (payload.result.function === "AuthenticateUserForAdmin") {
        if (payload.result.result === "EXECUTION_OK" || payload.result.result === "TSE1_ERROR_WRONG_STATE_NEEDS_PUK_CHANGE") {
          this.tseDevice.GFE_Setup();
        } /*else if (payload.result.result === "TSE1_ERROR_WRONG_STATE_NEEDS_PUK_CHANGE") {
          this.tseDevice.GFE_SetupForPrinter();
        }*/ else {
          this.tseErrorMessage = `Es ist ein Fehler aufgetreten<br>(${payload.result.result})`;
          this.tseLoading = false;
        }
      }

      //SETUP
      if (payload.result.function === "SetUp") {
        if (payload.result.result === "EXECUTION_OK" || payload.result.result === "OTHER_ERROR_TSE_ALREADY_SET_UP") {
          //GET STORAGE INFO FROM TSE DEVICE
          this.getLogCert();
        } else {
          this.tseErrorMessage = `Es ist ein Fehler aufgetreten<br>(${payload.result.result})`;
          this.tseLoading = false;
        }
      }
    },

    getLogCert() {
      //TSE ALREADY SETUP -> PROCEED WITH SAVING DEVICE IN DATABASE
      this.tseDevice.GFE_GetLogMessageCertificate();

      Events.$once("GFE_OnReceive", (payload) => {
        if (payload.result.function === "GetLogMessageCertificate") {
          if (payload.result.result === "EXECUTION_OK") {
            this.getStorageInfo(payload.result.output.logMessageCertificate);
          } else {
            this.tseErrorMessage = `Es ist ein Fehler aufgetreten<br>(${payload.result.result})`;
            this.tseLoading = false;
          }
        }
      });
    },

    getStorageInfo(logCertificate) {
      //TSE ALREADY SETUP -> PROCEED WITH SAVING DEVICE IN DATABASE
      this.tseDevice.GFE_GetStorageInfo();

      Events.$once("GFE_OnReceive", (payload) => {
        if (payload.result.function === "GetStorageInfo") {
          if (payload.result.result === "EXECUTION_OK") {
            this.setupFinish(logCertificate, payload.result.output);
          } else {
            this.tseErrorMessage = `Es ist ein Fehler aufgetreten<br>(${payload.result.result})`;
            this.tseLoading = false;
          }
        }
      });
    }
  }
}
</script>