<template>
  <v-stepper v-model="step">
    <v-divider class="ma-0"/>
    <v-stepper-header>
      <v-stepper-step
          :complete="step > 1"
          :step="1"
      >
        {{ $t('generic.lang_generalInfo') }}
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :step="2">
        {{ $t('generic.lang_dataConfirmation') }}
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :step="3">
        {{ $t('generic.lang_checkout') }}
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content class="pb-0" :step="1">

        <v-card
            elevation="0"
            class="pa-0"
        >
          <v-card-text class="px-0">
            <v-form v-model="valid" ref="fiscalyForm" lazy-validation>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                      :disable="loading"
                      :loading="loading"
                      counter
                      maxlength="200"
                      v-model="form.companyName"
                      outlined
                      :label="$t('settings.lang_ownerOrCompanyName')"
                      :rules="[rules.required,rules.maxChars(form.companyName, 200)]"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                      :disable="loading"
                      :loading="loading"
                      counter
                      maxlength="200"
                      v-model="form.address"
                      outlined
                      :label="$t('settings.lang_addressStreetAndNumber')"
                      :rules="[rules.required,rules.maxChars(form.address, 200)]"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                      :disable="loading"
                      :loading="loading"
                      maxlength="10"
                      v-model="form.zipCode"
                      outlined
                      :label="$t('generic.lang_zip')"
                      :rules="[rules.required,rules.maxChars(form.zipCode, 10)]"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                      :disable="loading"
                      :loading="loading"
                      v-model="form.city"
                      maxlength="200"
                      outlined
                      :label="$t('settings.lang_city')"
                      :rules="[rules.required,rules.maxChars(form.city, 200)]"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                      :disable="loading"
                      :loading="loading"
                      maxlength="200"
                      v-model="form.state"
                      outlined
                      :label="$t('settings.lang_state')"
                      :rules="[rules.required,rules.maxChars(form.state, 200)]"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                      :disable="loading"
                      :loading="loading"
                      v-model="form.country"
                      outlined
                      :items="allowedCountries"
                      item-text="label"
                      item-value="value"
                      :label="$t('generic.lang_country')"
                      :rules="[rules.required]"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-radio-group
                      :disable="loading"
                      :loading="loading"
                      v-model="taxType"
                      row
                  >
                    <v-radio
                        :label="$t('erp.lang_TaxID')"
                        :value="1"
                    ></v-radio>
                    <v-radio
                        :label="$t('settings.lang_taxIdendNo')"
                        :value="2"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                      :disable="loading"
                      :loading="loading"
                      v-if="taxType === 1"
                      v-model="form.taxNumber"
                      outlined
                      :label="$t('erp.lang_TaxID')"
                      :rules="[rules.required,rules.minChars(form.taxNumber, 8)]"
                  />
                  <v-text-field
                      :disable="loading"
                      :loading="loading"
                      v-else
                      v-model="form.taxID"
                      outlined
                      :label="$t('settings.lang_taxIdendNo')"
                      :rules="[rules.required,rules.minChars(form.taxID, 8)]"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                      :disable="loading"
                      :loading="loading"
                      v-model="fiscalType"
                      outlined
                      :items="['Germany','Austria']"
                      item-text="label"
                      item-value="value"
                      :label="$t('settings.lang_fiscalType')"
                      :rules="[rules.required]"
                  />
                </v-col>
                <template v-if="fiscalType === 'Austria'">
                  <v-col cols="12" sm="6">
                    <v-text-field
                        :disable="loading"
                        :loading="loading"
                        v-model="form.fon_participant_id"
                        outlined
                        :label="$t('settings.lang_fonParticipantId')"
                        :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                        :disable="loading"
                        :loading="loading"
                        v-model="form.fon_user_id"
                        outlined
                        :label="$t('settings.lang_fonUserId')"
                        :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                        :disable="loading"
                        :loading="loading"
                        v-model="form.fon_user_pin"
                        outlined
                        :label="$t('settings.lang_fonUserPin')"
                        :rules="[rules.required]"
                    />
                  </v-col>
                </template>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
        <v-divider class="ma-0"/>
        <v-card-actions>

          <v-spacer/>
          <v-btn
              color="primary"
              :disabled="!valid"
              @click="next(2)"
          >
            {{ $t('generic.lang_next') }}
          </v-btn>
        </v-card-actions>
      </v-stepper-content>

      <v-stepper-content class="pb-0" :step="2">
        <v-card
            elevation="0"
            class="pa-0"
        >
          <v-card-text class="px-0">
            <v-row>
              <v-col cols="12">
                <v-alert type="warning">
                  {{ $t('settings.lang_dataConfirmationWarning') }}
                </v-alert>
              </v-col>
              <v-col cols="12" class="text-capitalize fsize-2">
                <div>
                  <strong>{{$t('settings.lang_ownerOrCompanyName')}} :</strong>
                  <span> {{form.companyName}} </span>
                </div>
                <div>
                  <strong>{{$t('settings.lang_addressStreetAndNumber')}} :</strong>
                  <span> {{form.address}} </span>
                </div>
                <div>
                  <strong>{{$t('generic.lang_zip')}} :</strong>
                  <span> {{form.zipCode}} </span>
                </div>
                <div>
                  <strong>{{$t('settings.lang_city')}} :</strong>
                  <span> {{form.city}} </span>
                </div>
                <div>
                  <strong>{{$t('settings.lang_state')}} :</strong>
                  <span> {{form.state}} </span>
                </div>
                <div>
                  <strong>{{$t('settings.lang_country')}} :</strong>
                  <span> {{selectedCountryName}} </span>
                </div>
                <div>
                  <strong>{{taxType === 1? $t('erp.lang_TaxID') : $t('settings.lang_taxIdendNo')}} :</strong>
                  <span> {{taxType === 1? form.taxNumber : form.taxID}} </span>
                </div>
                <div>
                  <strong>{{$t('settings.lang_fiscalType')}} :</strong>
                  <span> {{fiscalType}} </span>
                </div>
                <template v-if="fiscalType === 'Austria'">
                  <div>
                    <strong>{{$t('settings.lang_fonParticipantId')}} :</strong>
                    <span> {{form.fon_participant_id}} </span>
                  </div>
                  <div>
                    <strong>{{$t('settings.lang_fonUserId')}} :</strong>
                    <span> {{form.fon_user_id}} </span>
                  </div>
                  <div>
                    <strong>{{$t('settings.lang_fonUserPin')}} :</strong>
                    <span> {{form.fon_user_pin}} </span>
                  </div>
                </template>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                    :disable="loading"
                    :loading="loading"
                    v-model="confirmed"
                    outlined
                    :label="$t('generic.lang_byCheckingYouConfirmData')"
                    :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card-actions>
          <v-btn
              color="primary"
              @click="step = 1"
          >
            {{ $t('generic.lang_previous') }}
          </v-btn>
          <v-spacer/>
          <v-btn
              color="primary"
              :disabled="!valid || !confirmed || loading"
              :loading="loading"
              @click="next(3)"
          >
            {{ $t('generic.lang_continue') }}
          </v-btn>
        </v-card-actions>
      </v-stepper-content>

      <v-stepper-content class="pb-0" :step="3">
        <v-alert type="info">
          {{ ('settings.lang_stripFiskalySubscriptionMSG') }}
        </v-alert>

        <v-card-actions>
          <v-btn
              color="primary"
              @click="step = 2"
          >
            {{ $t('generic.lang_previous') }}
          </v-btn>
          <v-spacer/>
          <v-btn
              color="primary"
              :disabled="!valid || !confirmed || loading"
              @click="requestFiscalyOrgCreation"
          >
            {{ $t('generic.lang_checkout') }}
          </v-btn>
        </v-card-actions>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import validation from "../../../mixins/validation/validation";
import {ENDPOINTS} from '@/config'
import {Events} from "@/plugins/events";

const countries = require('i18n-iso-countries');

countries.registerLocale(require("i18n-iso-countries/langs/de.json"));
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
countries.registerLocale(require("i18n-iso-countries/langs/ar.json"));

export default {
  name: "FiscalySubscriptionComponent",
  mixins: [validation],
  data() {
    return {
      step: 1,
      taxType: 1,
      valid: false,
      loading: false,
      confirmed: false,
      showWarning: false,
      fiscalType: 'Germany',
      form: {
        companyName: null,
        address: null,
        zipCode: null,
        city: null,
        state: null,
        country: null,
        taxID: null,
        taxNumber: null,
        fon_participant_id: null,
        fon_user_id: null,
        fon_user_pin: null,
      }
    }
  },
  computed: {
    allowedCountries() {
      const list = countries.getNames(this.$i18n.locale, {select: 'official'});
      const alpha2list = countries.getAlpha2Codes();
      const allowedCountryCodes = [
        "ABW", "AFG", "AGO", "AIA", "ALA", "ALB", "AND", "ARE", "ARG", "ARM", "ASM", "ATA",
        "ATF", "ATG", "AUS", "AUT", "AZE", "BDI", "BEL", "BEN", "BES", "BFA", "BGD", "BGR",
        "BHR", "BHS", "BIH", "BLM", "BLR", "BLZ", "BMU", "BOL", "BRA", "BRB", "BRN", "BTN",
        "BVT", "BWA", "CAF", "CAN", "CCK", "CHE", "CHL", "CHN", "CIV", "CMR", "COD", "COG",
        "COK", "COL", "COM", "CPV", "CRI", "CUB", "CUW", "CXR", "CYM", "CYP", "CZE", "DEU",
        "DJI", "DMA", "DNK", "DOM", "DZA", "ECU", "EGY", "ERI", "ESH", "ESP", "EST", "ETH",
        "FIN", "FJI", "FLK", "FRA", "FRO", "FSM", "GAB", "GBR", "GEO", "GGY", "GHA", "GIB",
        "GIN", "GLP", "GMB", "GNB", "GNQ", "GRC", "GRD", "GRL", "GTM", "GUF", "GUM", "GUY",
        "HKG", "HMD", "HND", "HRV", "HTI", "HUN", "IDN", "IMN", "IND", "IOT", "IRL", "IRN",
        "IRQ", "ISL", "ISR", "ITA", "JAM", "JEY", "JOR", "JPN", "KAZ", "KEN", "KGZ", "KHM",
        "KIR", "KNA", "KOR", "KWT", "LAO", "LBN", "LBR", "LBY", "LCA", "LIE", "LKA", "LSO",
        "LTU", "LUX", "LVA", "MAC", "MAF", "MAR", "MCO", "MDA", "MDG", "MDV", "MEX", "MHL",
        "MKD", "MLI", "MLT", "MMR", "MNE", "MNG", "MNP", "MOZ", "MRT", "MSR", "MTQ", "MUS",
        "MWI", "MYS", "MYT", "NAM", "NCL", "NER", "NFK", "NGA", "NIC", "NIU", "NLD", "NOR",
        "NPL", "NRU", "NZL", "OMN", "PAK", "PAN", "PCN", "PER", "PHL", "PLW", "PNG", "POL",
        "PRI", "PRK", "PRT", "PRY", "PSE", "PYF", "QAT", "REU", "ROU", "RUS", "RWA", "SAU",
        "SDN", "SEN", "SGP", "SGS", "SHN", "SJM", "SLB", "SLE", "SLV", "SMR", "SOM", "SPM",
        "SRB", "SSD", "STP", "SUR", "SVK", "SVN", "SWE", "SWZ", "SXM", "SYC", "SYR", "TCA",
        "TCD", "TGO", "THA", "TJK", "TKL", "TKM", "TLS", "TON", "TTO", "TUN", "TUR", "TUV",
        "TWN", "TZA", "UGA", "UKR", "UMI", "URY", "USA", "UZB", "VAT", "VCT", "VEN", "VGB",
        "VIR", "VNM", "VUT", "WLF", "WSM", "YEM", "ZAF", "ZMB", "ZWE"
      ];
      return Object.keys(list).map((key) => allowedCountryCodes.includes(alpha2list[key]) ? {
        value: alpha2list[key],
        label: list[key]
      } : null).filter(el => el !== null);
    },
    selectedCountryName(){
      return countries.getName(this.form.country ?? '',this.$i18n.locale);
    }
  },
  methods: {
    next(step) {
      if (!this.$refs.fiscalyForm.validate()) {
        return;
      }
      this.step = step;
    },
    requestFiscalyOrgCreation() {
      if (!this.$refs.fiscalyForm.validate()) {
        return;
      }
      this.loading = true;
      this.axios.post(ENDPOINTS.SETTINGS.FISCAL.FISCALY.CREATE, this.form).then((res) => {
        if (res.data.status) {
          // redirect to the stripe checkout url if all is good

        } else if (res.data.status === false) {
          Events.$emit("showSnackbar", {
            message: res.data.msg,
            color: "error"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      });
    }
  }
}
</script>

<style scoped>

</style>