<template>
  <v-stepper v-model="step">
    <v-stepper-items>
      <v-stepper-content class="pa-0" :step="1">
        <v-form v-model="valid" lazy-validation ref="fiskalySetupForm">
          <v-container fluid>
            <v-row>
              <v-col v-if="loading" cols="12">
                <v-progress-linear indeterminatecolor="primary"/>
              </v-col>
              <v-col v-if="alreadyExists" cols="12">
                <v-alert color="info" type="info" outlined>
                  {{ $t('settings.lang_fiscallyTseAlreadyBeenAdded') }}
                </v-alert>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field outlined :rules="[rules.required]" :label="$t('settings.lang_fiscalDeviceName')" v-model="form.name"/>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field outlined :label="$t('settings.lang_fiscalDeviceDescr')" v-model="form.description"/>
              </v-col>


              <v-col cols="12" sm="6">
                <v-text-field outlined :rules="[rules.required]" :label="$t('settings.lang_apiKey')" v-model="form.api_key"/>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field outlined type="password" :label="$t('settings.lang_secretKey')" :rules="[rules.required]" v-model="form.api_secret_key"/>
              </v-col>

              <v-col cols="12" sm="6">
                <v-select
                    :disable="saving_loader"
                    :loading="saving_loader"
                    v-model="form.fiscalType"
                    outlined
                    :items="['Germany','Austria']"
                    item-text="label"
                    item-value="value"
                    :label="$t('settings.lang_fiscalType')"
                    :rules="[rules.required]"
                />
              </v-col>
              <template v-if="form.fiscalType === 'Austria'">
                <v-col cols="12" sm="6">
                  <v-text-field
                      :disable="saving_loader"
                      :loading="saving_loader"
                      counter
                      maxlength="200"
                      v-model="form.companyName"
                      outlined
                      :label="$t('settings.lang_ownerOrCompanyName')"
                      :rules="[rules.required,rules.maxChars(form.companyName, 200)]"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-radio-group
                      :disable="saving_loader"
                      :loading="saving_loader"
                      v-model="taxType"
                      row
                  >
                    <v-radio
                        :label="$t('erp.lang_TaxID')"
                        :value="1"
                    ></v-radio>
                    <v-radio
                        :label="$t('settings.lang_taxIdendNo')"
                        :value="2"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                      :disable="saving_loader"
                      :loading="saving_loader"
                      v-if="taxType === 1"
                      v-model="form.taxNumber"
                      outlined
                      :label="$t('erp.lang_TaxID')"
                      :rules="[rules.required,rules.minChars(form.taxNumber, 8)]"
                  />
                  <v-text-field
                      :disable="saving_loader"
                      :loading="saving_loader"
                      v-else
                      v-model="form.taxID"
                      outlined
                      :label="$t('settings.lang_taxIdendNo')"
                      :rules="[rules.required,rules.minChars(form.taxID, 8)]"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                      :disable="saving_loader"
                      :loading="saving_loader"
                      v-model="form.fon_participant_id"
                      outlined
                      :label="$t('settings.lang_fonParticipantId')"
                      :rules="[rules.required]"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                      :disable="saving_loader"
                      :loading="saving_loader"
                      v-model="form.fon_user_id"
                      outlined
                      :label="$t('settings.lang_fonUserId')"
                      :rules="[rules.required]"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                      :disable="saving_loader"
                      :loading="saving_loader"
                      v-model="form.fon_user_pin"
                      outlined
                      :label="$t('settings.lang_fonUserPin')"
                      :rules="[rules.required]"
                  />
                </v-col>
              </template>

              <v-col class="text-right" cols="12">
                <v-spacer/>
                <v-btn :loading="saving_loader" @click="save" :disabled="saving_loader || !valid" depressed
                       class="mx-auto" color="success">{{ $t('generic.lang_save') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>

        </v-form>
      </v-stepper-content>
      <v-stepper-content class="pa-0" :step="2">
        <v-alert type="info" text tile>
          {{ $t('settings.lang_fiskalySetupMSG') }}
        </v-alert>
        <v-card-actions>
          <v-btn @click="step=1" :disabled="this.saving_loader" color="success">
            {{ this.$t('settings.lang_alreadyHaveStripeKey') }}
          </v-btn>
          <v-spacer/>
          <v-btn v-if="false" @click="step=3" :disabled="this.saving_loader" color="primary">
            {{ this.$t('settings.lang_needSubscription') }}
          </v-btn>
        </v-card-actions>
      </v-stepper-content>
      <v-stepper-content class="pa-0" :step="3">
        <FiscalySubscriptionComponent/>
      </v-stepper-content>
    </v-stepper-items>
    <template v-if="step !== 2">
      <v-divider class="ma-0"/>
      <v-card-actions>
        <v-btn :loading="this.saving_loader" @click="step=2" text :disabled="this.saving_loader" color="error">
          {{ this.$t('generic.lang_cancelSetup') }}
        </v-btn>
      </v-card-actions>
    </template>
  </v-stepper>
</template>

<script>
import {ENDPOINTS} from '@/config'
import {Events} from "@/plugins/events";
import mixin from "../../../mixins/KeyboardMixIns";
import FiscalySubscriptionComponent from "./FiscalySubscriptionComponent";
import validation from "../../../mixins/validation/validation";

export default {
  name: "FiskalyComponent",
  components: {FiscalySubscriptionComponent},
  mixins: [validation],
  data() {
    return {
      taxType: 1,
      step: 2,
      saving_loader: false,
      alreadyExists: false,
      loading: false,
      valid: false,
      form: {
        name: "",
        description: "",
        fiscalType: 'Germany',
      }
    }
  },
  methods: {
    loadData() {
      this.loading = true;

      this.axios.post(ENDPOINTS.SETTINGS.FISCAL.FISCALDEVICEFISKALY.GET).then((res) => {
        if (res.data.status === 'SUCCESS') {
          if (res.data.data != "empty") {
            this.form = {...res.data.data};
            this.alreadyExists = true;
            this.saving_loader = true;
          } else {
            this.alreadyExists = false;
            this.saving_loader = false;
          }


        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    save() {
      if(!this.$refs.fiskalySetupForm.validate()){
        return;
      }
      this.saving_loader = true;
      this.axios.post(ENDPOINTS.SETTINGS.FISCAL.FISCALDEVICEFISKALY.CREATE, this.form).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('settings.lang_fiscalDeviceAdded'),
            color: "success"
          });
        } else if (res.data.status === false) {
          Events.$emit("showSnackbar", {
            message: res.data.msg,
            color: "error"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => {
        this.saving_loader = false;
      });
    }
  },
  mounted() {
    this.loadData();
    this.saving_loader = true;
  }
}
</script>

<style scoped>

</style>
