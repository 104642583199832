<template>
    <v-dialog v-model="showDialog" persistent max-width="800px">
        <v-card>
            <v-toolbar dark flat>
                <v-toolbar-title>TSE-Gerät deaktivieren</v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn icon @click="closeDialog">
                    <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card-text style="min-height: 250px;">
                <v-row>
                    <v-col cols="10" offset-md="1">
                        <v-alert icon="warning" dark class="text-center" color="error">
                            <b>Achtung</b>
                            <br>Mit dieser Funktion deaktivierst du das Fiskalgerät.
                            <br>Mit diesem Fiskalgerät kann dann keine Signierung mehr durchgeführt werden.
                            <br><br><b>Diese Aktion kann nicht rückgängig gemacht werden!!!</b>
                        </v-alert>

                        <v-checkbox :disabled="deactivateLoading"
                                    label="Ja, ich bin damit einverstanden, dass das Fiskalgerät deaktiviert wird"
                                    v-model="deactivateConfirm"></v-checkbox>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions>
                <v-btn :disabled="deactivateLoading" text color="primary" @click="closeDialog">{{$t("generic.lang_cancel")}}</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="!deactivateConfirm || deactivateLoading" :loading="deactivateLoading"
                       @click="deactivateTSE">Deaktivieren
                </v-btn>
            </v-card-actions>
        </v-card>

        <!-- TSE ERROR DIALOG -->
        <v-dialog persistent width="600" :value="tseErrorMessage.length > 0">
            <v-card>
                <v-card-title>TSE-Fehler</v-card-title>

                <v-card-text class="text-center">
                    <b>Fehler</b>
                    <br><span v-html="tseErrorMessage"></span>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="warning" @click="tseErrorMessage = ''">Verstanden</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>

<script>
import {Events} from "@/plugins/events";
import {mapGetters} from "vuex";
import {ENDPOINTS} from "@/config";

export default {
    name: "FiscalDeviceDeactivate",

    props: {
        showDialog: Boolean,
        fiscalDeviceID: String,
        fiscalDeviceIP: String,
        fiscalDeviceName: String,
        useProxy: Boolean
    },

    watch: {
        showDialog(val) {
            if (!val) {
                this.loading = false;

                //DISABLE LISTENER
                Events.$off("eposDevice", this.eposDeviceCallback);
                Events.$off("GFE_OnReceive", this.GFE_OnReceiveCallback)
            } else {
                Events.$on("eposDevice", this.eposDeviceCallback);
                Events.$on("GFE_OnReceive", this.GFE_OnReceiveCallback);
            }
        }
    },

    data() {
        return {
            deactivateConfirm: false,
            deactivateLoading: false,
            tseDevice: null,
            tseErrorMessage: ""
        }
    },

    computed: {
        ...mapGetters({
            fiscalClient: 'tse/fiscalClient'
        })
    },

    beforeDestroy() {
        Events.$off("eposDevice", this.eposDeviceCallback);
        Events.$off("GFE_OnReceive", this.GFE_OnReceiveCallback)
    },

    methods: {
        closeDialog() {
            this.$emit("closeDialog");
        },
        deactivateTSE() {
            this.deactivateLoading = true;

            //CHECK IF TSE CLIENT IS SETUP
            if(this.fiscalClient === null) {
                return;
            }

            //ADD NEW TSE PRINTER
            let tseDevices = this.$eposClass.getTSEPrinters();

            console.log(tseDevices);

            //CHECK IF TSE PRINTER EXISTS
            if(!tseDevices.hasOwnProperty(this.fiscalClient.id)) {
                //ADD TSE
                this.$eposClass.addTSEPrinter(new this.$epson.ePOSDevice(), {
                    id: this.fiscalClient.id,
                    ip: this.fiscalClient.device.ip,
                    TSEProxyIPAdress: this.fiscalClient.device.TSEProxyIPAdress,
                    port: this.fiscalClient.device.port,
                    deviceID: this.fiscalClient.device.deviceID,
                    adminID: 'Administrator',
                    clientID: this.fiscalClient.clientID,
                }, false, false, this.fiscalClient.device.useTSEProxy === 1);
            }

            this.tseDevice = this.$eposClass.getTSEPrinter(this.fiscalClient.id);

            if(this.tseDevice?.tseReady) {
                this.tseDevice.GFE_DisableSecureElement();
            }
            else {
                if(this.tseDevice.connected) {
                    //TSE WAS ADDED BUT ISNT READY YET
                    this.deactivateLoading = false;
                    this.tseErrorMessage = "TSE ist nicht einsatzbereit...probiere es erneut.";
                }
                else {
                    //TRY TO RE-CONNECT!!!
                    this.tseDevice.connect();
                }
            }
        },

        eposDeviceCallback(payload) {
            if (payload.action === "createDeviceCallback.error") {
                this.deactivateLoading = false;
                this.tseErrorMessage = `Fiskalgerät konnte nicht deaktiviert werden.<br>(${payload.code})`;
            }

            if (payload.action === "connectCallback.error") {
                //CONNECTION ERROR
                this.deactivateLoading = false;
                this.tseErrorMessage = "Verbindung mit der TSE konnte nicht aufgebaut werden. Bitte stelle sicher, dass das Gerät eingeschaltet ist."
            }
        },
        GFE_OnReceiveCallback(payload) {
            if (payload.result.function === "GetStorageInfo") {
                if (payload.result.result === "EXECUTION_OK") {
                    this.tseDevice.GFE_DisableSecureElement();
                }
            }

            if (payload.result.function === "DisableSecureElement") {
                if (payload.result.result === "EXECUTION_OK" || payload.result.result === "TSE1_ERROR_TSE_DECOMMISSIONED") {
                    this.finishDisableSecureElement();
                } else if (payload.result.result === "TSE1_ERROR_TSE_HAS_UNFINISHED_TRANSACTIONS") {
                    this.getUnfinishedTransaction();
                } else {
                    this.tseErrorMessage = `Es ist ein Fehler aufgetreten<br>(${payload.result.result})`;
                    this.deactivateLoading = false;
                }
            }
        },
        getUnfinishedTransaction() {
            //ePOSCLASS SHOULD NOT LISTEN TO OUR EVENTS!!!
            this.tseDevice.skipGFEReceiveCallback = true;

            //FIRST GET REGISTERED CLIENTS
            this.tseDevice.GFE_GetRegisteredClientList();

            Events.$once("GFE_OnReceive", (payload) => {
                if (payload.result.function === "GetRegisteredClientList") {
                    if (payload.result.result === "EXECUTION_OK") {
                        //REGISTERED CLIENTS
                        let clients = payload.result.output.registeredClientIdList;

                        this.one_by_one(clients, (client) => {
                            return new Promise((resolve, reject) => {
                                ////console.log(client, "Getting started transactions");

                                this.tseDevice.GFE_GetStartedTransactionList(client);

                                Events.$once("GFE_OnReceive", (payload) => {
                                    if (payload.result.function === "GetStartedTransactionList") {
                                        if (payload.result.result === "EXECUTION_OK") {
                                            //UNFINISHED TRANSACTIONS
                                            let transactionNumbers = payload.result.output.startedTransactionNumberList;

                                            if(transactionNumbers.length === 0) {
                                                resolve();
                                                return;
                                            }

                                            //AUTH CLIENT AS TIME ADMIN
                                            this.tseDevice.GFE_GetChallenge(client);

                                            Events.$once("GFE_OnReceive", (payload) => {
                                                if (payload.result.function === "GetChallenge") {
                                                    if (payload.result.result === "EXECUTION_OK") {
                                                   //     //console.log(client, "Got challenge");
                                                        this.tseDevice.GFE_AuthenticateUserForTimeAdmin(client, payload.result.output.challenge);

                                                        Events.$once("GFE_OnReceive", (payload) => {
                                                            if (payload.result.function === "AuthenticateUserForTimeAdmin") {
                                                                if (payload.result.result === "EXECUTION_OK") {
                                                          //          //console.log(client, "Authenticated client");

                                                                    this.one_by_one(transactionNumbers, (transactionNumber) => {
                                                                        return new Promise((resolve2, reject2) => {
                                                                            //console.log(client, "Transaction #" + transactionNumber);

                                                                            this.tseDevice.finishUnfinishedTransaction(client, transactionNumber)

                                                                            Events.$once("GFE_OnReceive", (payload) => {
                                                                                if (payload.result.function === "FinishTransaction") {
                                                                                    if (payload.result.result === "EXECUTION_OK") {
                                                                                        resolve2();
                                                                                    } else {
                                                                                        reject2();
                                                                                    }
                                                                                }
                                                                            });
                                                                        });
                                                                    }, () => {
                                                                        resolve();
                                                                    });
                                                                }
                                                                else {
                                                                    reject();
                                                                }
                                                            }
                                                        });
                                                    }
                                                    else {
                                                        reject();
                                                    }
                                                }
                                            });
                                        } else {
                                            reject();
                                        }
                                    }
                                });
                            });
                        }, () => {
                            this.tseDevice.GFE_DisableSecureElement();
                        })
                    }
                }
            });
        },
        one_by_one(objects_array, iterator, callback) {
            let start_promise = objects_array.reduce(function (prom, object) {
                return prom.then(function () {
                    return iterator(object);
                });
            }, Promise.resolve()); // initial
            if (callback) {
                start_promise.then(callback);
            } else {
                return start_promise;
            }
        },
        finishDisableSecureElement() {
            this.axios.post(ENDPOINTS.SETTINGS.FISCAL.FISCALDEVICE.DELETE, {
                fiscalDeviceID: [this.fiscalClient.device.id]
            }).then((res) => {
                if (res.data.status === 'SUCCESS') {
                    Events.$emit("showSnackbar", {
                        message: "Fiskalgerät wurde erfolgreich deaktiviert!",
                        color: "success"
                    });

                    /*
                    this.$router.replace({
                        name: 'settings.fiscalisation_de.fiscalDevice'
                    });
                    */

                    window.location = "";
                } else {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });

                    this.deactivateLoading = false;
                    this.deactivateConfirm = false;
                }
            }).catch(() => {
                Events.$emit("showSnackbar", {
                    message: this.$t('generic.lang_errorOccurred'),
                    color: "error"
                });

                this.deactivateLoading = false;
                this.deactivateConfirm = false;
            });
        }
    }
}
</script>
