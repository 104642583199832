<template>
  <div class="mb-3 card transparent">
    <div>
      <v-tabs v-model="tab" class="rounded-t">
        <v-tab key="list">
          {{ $t('settings.lang_fiscalDevice') }}
        </v-tab>
        <v-tab key="add" v-if="$store.getters['permissions/checkModule'](34)">{{ $t('settings.lang_addFiscalDevice') }}</v-tab>

        <v-tab key="fiskaly">Fiskaly</v-tab>

        <v-tabs-items v-model="tab">
          <v-tab-item key="list">

            <!-- Table -->
            <Datatable v-show="showTable" ref="fiscalDeviceDatatable"
                       :api-endpoint="dataTableEndpoint"
                       :datatable-headers="datatableHeaders"
                       :excel-columns="excelColumns"
                       excel-file-name="Fiskal_Geräte"
                       @editEntry="entryEdit"
                       @deleteEntry="entryDelete"
                       @deleteEntries="entriesDelete"
                       show-delete-buttons
                       :show-edit-buttons="$store.getters['permissions/checkModule'](34)"
                       :permissionDelete="this.$store.getters['permissions/checkPermission']('set_modules')"
                       :permissionEdit="this.$store.getters['permissions/checkPermission']('set_modules')"
            />

            <!-- Table: Click -->
            <FiscalDevicesEdit v-if="showUpdate" style="margin-top: 20px;" @showList="showList"
                               :fiscal-i-d="fiscalID"></FiscalDevicesEdit>
          </v-tab-item>

          <v-tab-item key="add" v-if="$store.getters['permissions/checkModule'](34)">
            <FiscalDevicesCreate @showList="showList"></FiscalDevicesCreate>
          </v-tab-item>


          <v-tab-item keu="fiskaly">
            <fiskaly-component />
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </div>
  </div>
</template>

<style>
.dataTables_filter {
  display: none;
}
</style>

<script>


import Datatable from "../../datatable/Datatable";
import {ENDPOINTS} from '@/config'
import {Events} from "@/plugins/events";
import FiscalDevicesCreate from "@/components/settings/fiscal_de/FiscalDevicesCreate";
import FiscalDevicesEdit from "@/components/settings/fiscal_de/FiscalDevicesEdit";
import FiskalyComponent from "@/components/settings/fiscal_de/FiskalyComponent";

export default {
  components: {
    FiskalyComponent,
    FiscalDevicesEdit,
    FiscalDevicesCreate,
    Datatable
  },

  data() {
    return {
      ENDPOINTS,
      fiscalID: 0,
      search: "",
      loading: false,
      showTable: true,
      showCreate: false,
      showUpdate: false,
      tab: 0,
      additiveData: [],
    }
  },
  computed: {
    dataTableEndpoint(){
      return this.$store.getters['permissions/checkModule'](74)? ENDPOINTS.DATATABLES.SETTINGS.FISCAL.FISCALDEVICESAT : ENDPOINTS.DATATABLES.SETTINGS.FISCAL.FISCALDEVICES;
    },
    // --- Datatable ---
    datatableHeaders() {
      return this.$store.getters['permissions/checkModule'](34)?[
        { text: 'ID', align: 'left', value: 'id', width: 80, hide: true},
        {text: this.$t('settings.lang_fiscalDeviceName'), value: 'name'},
        {text: this.$t('settings.lang_fiscalDeviceDescr'), value: 'descr'},
        {text: this.$t('settings.lang_fiscalDeviceIP'), value: 'deviceIP'},
        {text: this.$t('settings.lang_fiscalDeviceID'), value: 'deviceID'}
      ]:[
        { text: 'ID', align: 'left', value: 'id', width: 80, hide: true},
        {text: this.$t('settings.lang_fiscalDeviceName'), value: 'name'},
        {text: this.$t('settings.lang_fiscalDeviceDescr'), value: 'descr'},
        {text: this.$t('settings.lang_fiscalCertificateNumber'), value: 'certificate'}
      ]
    },
    excelColumns() {
      return [

        {
          label: "ID",
          field: "id",
        },
        {
          label: this.$t('settings.lang_fiscalDeviceName'),
          field: "name",
        },
        {
          label: this.$t('settings.lang_fiscalDeviceDescr'),
          field: "descr",
        },
        {
          label: this.$t('settings.lang_fiscalDeviceIP'),
          field: "deviceIP",
        },
        {
          label: this.$t('settings.lang_fiscalDeviceID'),
          field: "deviceID",
        },
      ];
    }
  },

  watch: {
    tab: function () {
      if (this.tab === 1) {
        this.showTable = true;
        this.showUpdate = false;
      }
    }
  },

  methods: {
    createNew: function () {
      this.resetData();
      this.showCreate = true;
      this.tab = 1;
      this.showTable = false;
    },
    deleteData: function (idsToDelete = []) {
      this.$swal({
        title: this.$t('settings.lang_deleteFiscalDeviceHead'),
        text: this.$t('settings.lang_deleteFiscalDeviceBody'),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
            idsToDelete = [];
            idsToDelete.push(parseInt(this.id));
          }
          this.axios.post(ENDPOINTS.SETTINGS.FISCAL.FISCALDEVICE.DELETE, {
            fiscalDeviceID: idsToDelete
          }).then((res) => {
            if (res.data.status === 'SUCCESS') {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_actionSuccessful'),
                color: "success"
              });

              this.resetData();
              this.$refs.fiscalDeviceDatatable.getDataFromApi();
              this.$refs.fiscalDeviceDatatable.resetSelectedRows();
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },
    resetData: function () {
      this.showCreate = false;
      this.showUpdate = false;
      this.showTable = true;
      this.id = null;
      this.fiscalDeviceID = "local_TSE";
      this.fiscalDeviceName = "";
      this.fiscalDeviceDescr = "";
      this.fiscalDeviceIP = "";
      this.fiscalDeviceSSL = 0;
    },
    showList: function () {
      this.resetData();

      this.$refs.fiscalDeviceDatatable.getDataFromApi();
      this.$refs.fiscalDeviceDatatable.resetSelectedRows();

      this.tab = 0;
      this.showUpdate = false;
      this.showTable = true;
    },
    entryEdit(entry) {
      this.fiscalID = parseInt(entry.id);

      this.showUpdate = true;
      this.showTable = false;
    },
    entryDelete(entry) {
      this.id = entry.id;
      this.deleteData();
    },
    entriesDelete(entries) {
      this.deleteData(entries);
    }
  },
}
</script>
